var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        size: "large",
        display: _vm.display,
        padding: "p-0",
        hideClose: "",
      },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "heading-part p-4 lg:p-0" },
        [
          _c(
            "div",
            { staticClass: "md:w-3/5 mx-auto px-4 pt-4 text-center z-10" },
            [
              _c(
                "p",
                {
                  staticClass: "text-xl text-center text-grey mb-6 capitalize",
                },
                [_vm._v(" Hi " + _vm._s(_vm.name) + " ")]
              ),
              _c("p", { staticClass: "text-center mt-4" }),
              _c(
                "h4",
                { staticClass: "text-xl md:text-2xl text-center font-bold" },
                [
                  _vm._v(" 🎉 "),
                  _c("br"),
                  _c("span", { staticClass: "primary-text" }, [
                    _vm._v(" " + _vm._s(_vm.title)),
                  ]),
                ]
              ),
              _c("p", { staticClass: "text-grey" }, [
                _vm._v(" " + _vm._s(_vm.description) + " "),
              ]),
              _c("Button", {
                staticClass: "mt-10 z-10",
                attrs: {
                  outline: "",
                  text: "Make first payment",
                  width: "w-full",
                },
                on: {
                  click: function ($event) {
                    return _vm.handleCheckout()
                  },
                },
              }),
            ],
            1
          ),
          _c("TopSvg", { staticClass: "top-left-coins hidden md:block" }),
          _c("TopRight", { staticClass: "top-right-coins hidden md:block" }),
          _c("BottomCoins", { staticClass: "bottom-coins hidden md:block" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }