<template>
  <Modal
    size="large"
    :display="display"
    padding="p-0"
    hideClose
    @close="$emit('close')"
  >
    <div class="heading-part p-4 lg:p-0">
      <div class="md:w-3/5 mx-auto px-4 pt-4 text-center z-10">
        <p class="text-xl text-center text-grey mb-6 capitalize">
          Hi {{ name }}
        </p>
        <p class="text-center mt-4"></p>
        <h4 class="text-xl md:text-2xl text-center font-bold">
          🎉 <br />
          <span class="primary-text"> {{ title }}</span>
        </h4>
        <p class="text-grey">
          {{ description }}
        </p>
        <Button
          outline
          text="Make first payment"
          width="w-full"
          class="mt-10 z-10"
          @click="handleCheckout()"
        />
      </div>
      <TopSvg class="top-left-coins hidden md:block" />
      <TopRight class="top-right-coins hidden md:block" />
      <BottomCoins class="bottom-coins hidden md:block" />
    </div>
  </Modal>
</template>
<script>
  import { Modal } from "@/UI/Modals";
  import TopSvg from "./top-left.vue";
  import TopRight from "./Top-right.vue";
  import BottomCoins from "./BottomCoins.vue";
  import { Button } from "@/UI/Button";
  export default {
    components: {
      TopSvg,
      TopRight,
      BottomCoins,
      Modal,
      Button,
    },
    props: {
      title: {
        type: String,
        default: "Congratulations",
        required: false,
      },
      description: {
        type: String,
        default: "",
        required: false,
      },
      name: {
        type: String,
        default: "",
        required: false,
      },
      display: {
        type: Boolean,
        default: false,
        required: true,
      },
    },
    methods: {
      handleCheckout() {
        this.$emit("next");
      },
    },
  };
</script>
<style scoped>
  .heading-part {
    position: relative;
    /* background: linear-gradient(
        217.04deg,
        #c6003b -191.09%,
        #6a30c3 77.43%,
        #63dfdf 156.06%
      ),
      #6a30c3; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: grid;
    place-items: center;
    overflow: hidden;
  }
  .top-left-coins {
    position: absolute;
    top: -2.5rem;
    left: 0;
    z-index: 2;
  }
  .top-right-coins {
    position: absolute;
    top: -1rem;
    right: 0;
    z-index: 2;
  }
  .bottom-coins {
    position: absolute;
    bottom: 1rem;
    right: 0;
    z-index: 2;
  }
  @media (min-width: 768px) {
    .heading-part {
      height: 40rem;
    }
  }
</style>
